import Global from "./Global";

export default class CartData {

    static addToCart = (orderItems) => {

      if ( !this.items ) {
        this.items = [];
      }

      this.items.forEach(item => {
        orderItems.forEach((orderItem, index) => {
          if ( item.id == orderItem.id && item.selected_mod_items == orderItem.selected_mod_items ) {
            // Modify the item
            item.quantity = item.quantity + orderItem.quantity;
            if (item.quantity > Global.maxItems) {
              item.quantity = Global.maxItems;
            }
            orderItems.splice(index, 1);
          }
        });
      });

      orderItems.forEach(orderItem => {
        Global.dataBackup.menu_categories.forEach(menu_category => {
          if ( menu_category.id === orderItem.cat_id ) {
            menu_category.items.forEach(item => {
              if ( item.id === orderItem.id ) {
                orderItem.base_price = item.price;
              }
            });
          }
        });

        orderItem.modifiers.forEach(modifier => {
          modifier.items.forEach(item => {
            if ( item.isSelected ) {
              orderItem.price += item.price;
            }
          });
        });
        
        this.items.push(orderItem);
      });
    
    }

    static deleteFromCart = (item) => {
      this.items.forEach((element, index) => {
        if ( JSON.stringify(item) === JSON.stringify(element) ) {
          this.items.splice(index, 1);
        }
      });
    }

}