import React, { Component } from 'react';
import { View, Image, StatusBar, StyleSheet, TouchableOpacity, Dimensions, TextInput, Text, FlatList, ScrollView } from 'react-native';
import MenuController from '../controller/MenuController';
import { Ionicons } from '@expo/vector-icons';
import Global from '../model/util/Global';
import RadioForm, { RadioButton, RadioButtonInput, RadioButtonLabel } from 'react-native-simple-radio-button';

class Menu extends Component {

  constructor(props) {
    super(props);

    this.bannerHeight = Math.round(Dimensions.get('window').height) * 20 / 100;
    
    this.controller = new MenuController(this);
  }

  render() {

    return (
      <View style = { Styles.container }>

        <StatusBar barStyle="light-content"/>

        <View style = { Styles.headingContainer }>
            <View>
              <TextInput placeholder = "What are you looking for?" placeholderTextColor = { Global.color.light_gray } style = { Styles.inputText } onChangeText={text => this.controller.readText(text)}></TextInput>
              <View style = {{ display: 'flex', position: "absolute", marginTop: 24, right: 10 }}>
                <Ionicons name = "ios-search" size = { 24 } color = "#a8a8a8" />
              </View>
            </View>
        </View>

        <View style = { Styles.bodyContainer }>
            <View>
              <FlatList
                keyExtractor = { item => item.id.toString() }
                horizontal = { true }
                showsHorizontalScrollIndicator = { false }
                data = { this.state.data.menu_categories }
                extraData = { this.state.refresh }
                renderItem = {({ item, index }) => (
                  <Text style = { [Styles.textCategories, index == this.state.categoryIndex ? Styles.textCatActive : Styles.textCatInactive] } onPress = {() => { this.controller.onCategoryPressed(index); }}>
                    { item.name }
                  </Text>
                )}
              />

              {/* <View style = { Styles.line } /> */}
            </View>

            <FlatList
              keyExtractor = { item => item.id.toString() }
              data = { this.state.data.menu_categories }
              extraData = { this.state.refresh }
              contentInset = {{ bottom: 25 }}
              ref = { ref => (this.flatListRef = ref) }
              onViewableItemsChanged = { this.controller.onViewableItemsChanged }
              viewabilityConfig = {{
                itemVisiblePercentThreshold: 50
              }}
              showsVerticalScrollIndicator = { false }
              renderItem = {({ item }) => (
                <View>
                  <Text style = { Styles.heading }>{ item.name }</Text>

                  {
                    item.items.map((item, index) => {

                    const view = <View key = { index } style = { Styles.itemContainer }>
                          <View style = {{ flexDirection: 'row', flex: 1 }}>
                            <Image
                              style = { Styles.fitIcon }
                              resizeMode = { 'cover' }
                              source = {{ uri: Global.mediaUrl + item.image }}
                            />
                            <View style = {{ flex: 1 }}>
                              <View style = {{ flex: 1, marginStart: 6 }}>
                                <Text numberOfLines = {2} style = { [Styles.itemTitle, item.addedToCart ? Styles.titleColorSelected : Styles.titleColorUnselected] }>{ item.name }</Text>
                                <Text numberOfLines = {3} style = {{ fontSize: 12, color: Global.color.gray, marginTop: 3 }}>{ item.description }</Text>
                              </View>
                              <View style = {{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 0, marginStart: 7, alignItems: 'end' }}>
                                <Text style = { [Styles.itemPrice, item.addedToCart ? Styles.titleColorSelected : Styles.titleColorUnselected] }>{ this.state.data.currency + '. ' + item.price }</Text>
                                <TouchableOpacity style = { Styles.addButton } onPress = {() => { this.controller.addIsPressed(item); }}>
                                  <Text style = {{ color: 'white', fontSize: 16 }}>Add</Text>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                      </View>
                      return view;
                    })
                  }
                </View>
              )}
            />

        </View>

        
        <View style = { [{ height: '100%', width: '100%', position: 'absolute', alignItems: 'center', justifyContent: 'flex-end' }, this.state.popupVisibility ? Styles.show : Styles.hide] }>
          <View style = {{ backgroundColor: 'black', opacity: 0.6, height: '100%', width: '100%', position: 'absolute' }}></View>
          
          <View style = {{ backgroundColor: 'white', borderRadius: 12, width: '100%', height: '80%' }}>
              <TouchableOpacity style = {{ backgroundColor: 'white', width: 30, height: 30, borderRadius: 8, top: 10, right: 10, alignItems: 'center', justifyContent: 'center', position: 'absolute' }} onPress = {() => { this.controller.closePopup(); }}>
                <Text style = {{ color: 'white', fontSize: 25, color: Global.color.gray, fontWeight: '500' }}>X</Text>
              </TouchableOpacity>
              
              <Text style = {{ color: Global.color.dark_gray, fontSize: 24, fontWeight: '600', marginHorizontal: 18, marginTop: 10, textAlign: 'center', marginBottom: 8 }}>{this.state.selectedItem.name}</Text>
              <Text style = {{ color: Global.color.gray, marginHorizontal: 18, textAlign: 'center', marginBottom: 8 }}>{this.state.selectedItem.description}</Text>

              <ScrollView style = {{ marginBottom: 140 }}>
                {
                  this.state.selectedItem.modifiers.map((item, index) => {
                    return <View key={index}>
                          <View style = {{ flexDirection: 'row', marginHorizontal: 12, alignItems: 'baseline', marginTop: 12, marginBottom: 6 }}>
                            <View style = {{ flexDirection: 'row', flex: 0.7, alignItems: 'baseline' }}>
                              <Text style = {{ color: Global.color.dark_gray, fontSize: 24, fontWeight: '600' }}>{item.name}</Text>
                              {
                                item.required == 0 ? <Text style = {{ color: Global.color.dark_gray }}> (Select upto {item.max_count})</Text> : null
                              }
                            </View>
                            <Text style = {{ color: Global.color.dark_gray, flex: 0.3, textAlign: 'right' }}>{item.required == 1 ? '1 Required' : 'Optional'}</Text>
                          </View>
                          <RadioForm
                              formHorizontal={false}
                              animation={true}
                              key={index}
                              style = {{ marginHorizontal: 12 }}
                            >
                          {
                            item.items.map((mod, ind) => {
                                return <RadioButton labelHorizontal={true} key={ind} style={{paddingVertical: 12, borderRadius: 8, borderColor: Global.color.dark_gray, borderWidth: 1, marginVertical: 6}}>
                                            <RadioButtonInput
                                              obj={{label: mod.name, value: mod.id }}
                                              index={ind}
                                              isSelected={mod.isSelected}
                                              onPress={(value) => {this.controller.modIsPressed(mod, item)}}
                                              borderWidth={2}
                                              buttonInnerColor={Global.color.gray}
                                              buttonOuterColor={Global.color.app_bg}
                                              buttonSize={item.required == 1 ? 16 : null}
                                              buttonOuterSize={item.required == 1 ? 28 : null}
                                              buttonStyle={{}}
                                              buttonWrapStyle={{marginLeft: 10}}
                                            />
                                            <RadioButtonLabel
                                              obj={{label: mod.name, value: mod.id }}
                                              index={ind}
                                              labelHorizontal={true}
                                              onPress={(value) => {this.controller.modIsPressed(mod, item)}}
                                              labelStyle={{fontSize: 20, color: Global.color.dark_gray}}
                                              labelWrapStyle={{flex: 1}}
                                            />

                                            <Text style = {{ color: Global.color.dark_gray, fontSize: 18, fontWeight: 'normal', marginEnd: 10, textAlign: 'center' }}>Rs. {mod.price}</Text>
                                            {/* {
                                              item.required == 0 ? <Text style = {{ color: Global.color.dark_gray, fontSize: 18, fontWeight: 'normal', marginEnd: 10, textAlign: 'center' }}>Rs. {mod.price}</Text> : null
                                            } */}
                                        </RadioButton>
                            })
                          }
                        </RadioForm>
                      </View>
                  })
                }
              </ScrollView>

              <View style = {{ position: 'absolute', bottom: 80, width: '100%', padding: 10, flexDirection: 'row', alignItems: 'center' }}>
                <TouchableOpacity style = {[ {borderRadius: 6, paddingHorizontal: 14, paddingVertical: 4}, (this.state.selectedItem.quantity > 1) ? {backgroundColor: Global.color.app_bg} : {backgroundColor: Global.color.disabled_gray} ]} disabled = { (this.state.selectedItem.quantity > 1) ? false : true } onPress = {() => { this.controller.decreaseQty(); }}>
                  <Text style = {{ color: 'white', fontSize: 28, fontWeight: '600' }}>-</Text>
                </TouchableOpacity>
                
                <Text style = {{ color: 'black', fontSize: 24, width: 40, textAlign: 'center', fontWeight: 'bold' }}>{this.state.selectedItem.quantity}</Text>
                
                <TouchableOpacity style = {[ {borderRadius: 6, paddingHorizontal: 14, paddingVertical: 4}, (this.state.selectedItem.quantity < 10) ? {backgroundColor: Global.color.app_bg} : {backgroundColor: Global.color.disabled_gray} ]} disabled = { (this.state.selectedItem.quantity < 10) ? false : true } onPress = {() => { this.controller.increaseQty(); }}>
                  <Text style = {{ color: 'white', fontSize: 28, fontWeight: '500' }}>+</Text>
                </TouchableOpacity>

                <TouchableOpacity style = {[ {paddingVertical: 10, borderRadius: 6, paddingHorizontal: 60, alignItems: 'center', justifyContent: 'center', flexDirection: 'row', right: 10, position: 'absolute'}, this.state.addToCartEnabled ? {backgroundColor: Global.color.app_bg} : {backgroundColor: Global.color.disabled_gray} ]} disabled = { this.state.addToCartEnabled ? false : true } onPress = {() => { this.controller.addToCart(); }}>
                  {/* <FontAwesome name="shopping-cart" size={24} color="white" /> */}
                  <Text style = {{ color: 'white', marginStart: 6, fontWeight: 'bold', fontSize: 18 }}>Add to Cart</Text>
                </TouchableOpacity>
              </View>
          </View>
        </View>
        
      </View>
    );
  }

}

export default Menu;

const Styles = StyleSheet.create ({
  container: {
    width: '100%',
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'white',
    paddingBottom: 80
  },
  heading: {
    fontWeight: 'bold',
    fontSize: 24,
    color: Global.color.gray,
    marginTop: 14,
    marginBottom: 6
  },
  line: {
    backgroundColor: Global.color.app_bg,
    height: 1,
    width: '100%'
  },
  text: {
      color: Global.color.app_bg,
      fontSize: 12
  },
  textCategories: {
      paddingVertical: 8,
      marginEnd: 20,
      fontWeight: 'regular',
      fontSize: 18
  },
  textCatActive: {
      color: Global.color.app_bg
  },
  textCatInactive: {
      color: Global.color.dark_gray
  },
  headingContainer: {
      width: '94%',
      marginTop: 10
  },
  bodyContainer: {
      flex: 1,
      width: "94%",
      marginTop: 8
  },
  fit: {
      height: 200
  },
  fitIcon: {
      margin: 0,
      width: 120,
      height: 120,
      borderRadius: 8
  },
  inputText: {
      backgroundColor: '#f7f7f7',
      fontSize: 18,
      paddingVertical: 14,
      paddingEnd: 35,
      paddingStart: 12,
      marginTop: 12,
      borderRadius: 10
  },
  itemContainer: {
      marginVertical: 10
  },
  itemTitle: {
      fontSize: 16,
      fontWeight: 'bold'
  },
  titleColorUnselected: {
    color: 'black'
  },
  titleColorSelected: {
    color: Global.color.app_bg
  },
  itemPrice: {
      fontSize: 18,
      fontWeight: 'bold'
  },
  addButton: {
    backgroundColor: Global.color.app_bg,
    paddingHorizontal: 38,
    paddingVertical: 6,
    borderRadius: 5
  },
  show: {
    display: 'flex'
  },
  hide: {
      display: 'none'
  }
})