import Global from "../model/util/Global";
import Api from "../model/Api";

export default class OrderController {

  constructor(view) {
    this.view = view;

    this.view.state = {
      orders: [],
      bill: {
        tax: 13
      }
    };
  }

  loadOrders = () => {
    const api = new Api();

    api.get(Global.apiUrl+'brand/'+Global.brandId+'/branch/'+Global.branchId+'/table/'+Global.tableNo+'/orders')
    .then(result => {
        this.view.setState({ 
          orders: result
        })
      }
    )
    .catch(err => {
      console.log( err );
    });
  }

  openItemDetails = (selectedItem) => {
    console.log( selectedItem );
  }

  hidePopup = () => {
    Global.popupOrderPlaced = 'none'
    this.view.forceUpdate()
  }

}