import React, { Component } from 'react';
import { View, StatusBar, Text, ScrollView, TouchableOpacity, TextInput, SafeAreaView, StyleSheet, Image } from 'react-native';
import CallWaiterController from '../controller/CallWaiterController';
import Global from '../model/util/Global';
import RadioForm, { RadioButton, RadioButtonInput, RadioButtonLabel } from 'react-native-simple-radio-button';

class CallWaiter extends Component {

  constructor(props) {
    super(props);
    
    this.controller = new CallWaiterController(this);
  }

  render() {
    return (
      <SafeAreaView style = { Styles.container }>

        <StatusBar barStyle="light-content"/>

        <ScrollView style = { Styles.scrollContainer }>

          <Text style = { Styles.heading }>Call the waiter{'\n'}at your table</Text>

          <Text style = { Styles.subheading }>How may I help you?</Text>

          <RadioForm
              formHorizontal={false}
              animation={true}
              style = {{ margin: 18 }}
            >
                {
                  this.state.askWaiterOptions.map((item, index) => {
                    return <RadioButton labelHorizontal={true} key={index} style={{paddingVertical: 12, borderRadius: 8, borderColor: Global.color.dark_gray, borderWidth: 1, marginVertical: 6}}>
                              <RadioButtonInput
                                obj={{label: item.label, value: index }}
                                index={index}
                                isSelected={item.isSelected}
                                onPress={(value) => {this.controller.optionIsPressed(index)}}
                                borderWidth={2}
                                buttonInnerColor={Global.color.gray}
                                buttonOuterColor={Global.color.app_bg}
                                buttonSize={item.required == 1 ? 16 : null}
                                buttonOuterSize={item.required == 1 ? 28 : null}
                                buttonStyle={{}}
                                buttonWrapStyle={{marginLeft: 10}}
                              />
                              <RadioButtonLabel
                                obj={{label: item.label, value: index }}
                                index={index}
                                labelHorizontal={true}
                                onPress={(value) => {this.controller.optionIsPressed(index)}}
                                labelStyle={{fontSize: 20, color: Global.color.dark_gray}}
                                labelWrapStyle={{flex: 1}}
                              />
                          </RadioButton>
                  })
                }
          </RadioForm>

          <TouchableOpacity style = {[ { backgroundColor: Global.color.app_bg, paddingVertical: 14, borderRadius: 6, paddingHorizontal: 60, alignItems: 'center', justifyContent: 'center', marginHorizontal: 14, marginBottom: 8 }, this.state.callEnabled ? {backgroundColor: Global.color.app_bg} : {backgroundColor: Global.color.disabled_gray} ]} disabled = { this.state.callEnabled ? false : true } onPress = {() => { this.controller.callWaiter() }}>
            <Text style = {{ color: 'white', marginStart: 6, fontWeight: 'bold', fontSize: 18 }}>CALL</Text>
          </TouchableOpacity>

          <Text style = { [Styles.subheading, {marginTop: 40}] }>How was your experience?</Text>

          <View style = {{ flexDirection: 'row', flex: 1, margin: 12, justifyContent: 'center', gap: 8 }}>
            <TouchableOpacity style = {{ alignItems: 'center' }} onPress = {() => { this.controller.faceIsPressed('worst') }}>
              <Image
                source = { require('../assets/feedback_smiley_01.png') }
                resizeMode = { 'contain' }
                style = { {width: 50, height: 50} }
              />
              {
                ( this.state.selectedFace === 'worst' ) ?<View style = {{ width: 14, height: 3, backgroundColor: Global.color.dark_gray, marginTop: 8 }} /> : null
              }
            </TouchableOpacity>
            <TouchableOpacity style = {{ alignItems: 'center' }} onPress = {() => { this.controller.faceIsPressed('poor') }}>
              <Image
                source = { require('../assets/feedback_smiley_02.png') }
                resizeMode = { 'contain' }
                style = { {width: 50, height: 50} }
              />
              {
                ( this.state.selectedFace === 'poor' ) ?<View style = {{ width: 14, height: 3, backgroundColor: Global.color.dark_gray, marginTop: 8 }} /> : null
              }
            </TouchableOpacity>
            <TouchableOpacity style = {{ alignItems: 'center' }} onPress = {() => { this.controller.faceIsPressed('medium') }}>
              <Image
                source = { require('../assets/feedback_smiley_03.png') }
                resizeMode = { 'contain' }
                style = { {width: 50, height: 50} }
              />
              {
                ( this.state.selectedFace === 'medium' ) ?<View style = {{ width: 14, height: 3, backgroundColor: Global.color.dark_gray, marginTop: 8 }} /> : null
              }
            </TouchableOpacity>
            <TouchableOpacity style = {{ alignItems: 'center' }} onPress = {() => { this.controller.faceIsPressed('good') }}>
              <Image
                source = { require('../assets/feedback_smiley_04.png') }
                resizeMode = { 'contain' }
                style = { {width: 50, height: 50} }
              />
              {
                ( this.state.selectedFace === 'good' ) ?<View style = {{ width: 14, height: 3, backgroundColor: Global.color.dark_gray, marginTop: 8 }} /> : null
              }
            </TouchableOpacity>
            <TouchableOpacity style = {{ alignItems: 'center' }} onPress = {() => { this.controller.faceIsPressed('excellent') }}>
              <Image
                source = { require('../assets/feedback_smiley_05.png') }
                resizeMode = { 'contain' }
                style = { {width: 50, height: 50} }
              />
              {
                ( this.state.selectedFace === 'excellent' ) ?<View style = {{ width: 14, height: 3, backgroundColor: Global.color.dark_gray, marginTop: 8 }} /> : null
              }
            </TouchableOpacity>
          </View>

          <TextInput
            multiline={true}
            numberOfLines={10}
            style={{ height:130, textAlignVertical: 'top', marginHorizontal: 18, marginVertical: 12, padding: 8, borderColor: Global.color.light_gray, borderWidth: 1, borderRadius: 6, fontSize: 18}}
            underlineColorAndroid="transparent"
            placeholder="Leave a review..."
            placeholderTextColor={Global.color.light_gray}
            onChangeText={(text) => this.setState({review: text})}
            value={this.state.review}
          />

          <TouchableOpacity style = {[ { backgroundColor: Global.color.disabled_gray, paddingVertical: 14, borderRadius: 6, paddingHorizontal: 60, alignItems: 'center', justifyContent: 'center', marginHorizontal: 18 }, this.state.postEnabled ? {backgroundColor: Global.color.app_bg} : {backgroundColor: Global.color.disabled_gray} ]} disabled = { this.state.postEnabled ? false : true } onPress = {() => { this.controller.postReview() }}>
            <Text style = {{ color: 'white', marginStart: 6, fontWeight: 'bold', fontSize: 18 }}>POST</Text>
          </TouchableOpacity>

          <View style = {{ height: 100 }} />
          
        </ScrollView>

        <View style = {[ Styles.modalTransBG, {display: this.state.displayPopup} ]}>
          <View style = { Styles.bg_popup_size }>
            <Image
              source = { require( '../assets/icon_review_posted.png' ) }
              resizeMode = { 'contain' }
              style = { Styles.image_size }
            />
            <Text style = { Styles.heading }>Review Posted!</Text>
            <TouchableOpacity style = {{ paddingVertical: 10, borderRadius: 6, paddingHorizontal: 60, alignItems: 'center', justifyContent: 'center', backgroundColor: Global.color.app_bg, marginTop: 15 }} onPress = {() => { this.controller.hidePopup(); }}>
              <Text style = {{ color: 'white', marginStart: 6, fontWeight: 'bold', fontSize: 18 }}>Great</Text>
            </TouchableOpacity>
          </View>
        </View>

        <View style = {[ Styles.modalTransBG, {display: this.state.displayWaiterPopup} ]}>
          <View style = { Styles.bg_popup_size }>
            <Image
              source = { require( '../assets/icon_call_waiter.png' ) }
              resizeMode = { 'contain' }
              style = { Styles.image_size }
            />
            <Text style = { Styles.heading }>Waiter is on the way!</Text>
            <TouchableOpacity style = {{ paddingVertical: 10, borderRadius: 6, paddingHorizontal: 60, alignItems: 'center', justifyContent: 'center', backgroundColor: Global.color.app_bg, marginTop: 15 }} onPress = {() => { this.controller.hideWaiterPopup(); }}>
              <Text style = {{ color: 'white', marginStart: 6, fontWeight: 'bold', fontSize: 18 }}>Great</Text>
            </TouchableOpacity>
          </View>
        </View>

      </SafeAreaView>
    );
  }

}

export default CallWaiter;

const Styles = StyleSheet.create ({
  container: {
    flex: 1,
    padding: 0,
    width: '100%',
    backgroundColor: Global.color.app_bg
},
textTitle: {
    fontWeight: "",
    width: "100%",
    fontSize: 26,
    marginTop: 16,
    marginStart: 20,
    color: 'white'
},
scrollContainer: {
    width: '100%',
    flex: 1,
    backgroundColor: 'white'
},
itemContainer: {
    flexDirection: "row",
    backgroundColor: 'white',
    margin: 10,
    paddingHorizontal: 14,
    paddingVertical: 20,
    borderRadius: 8
},
addButtonContainer: {
  flexDirection: 'row',
  justifyContent: 'center'
},
addButton: {
  alignItems: 'center',
  flexDirection: 'row',
  backgroundColor: Global.color.app_bg,
  paddingHorizontal: 26,
  paddingVertical: 10,
  borderRadius: 6
},
textName: {
    fontSize: 18,
    color: 'black',
    margin: 16
},
textModifiers: {
    fontSize: 12,
    marginTop: 4,
    color: 'black'
},
itemDetailContainer: {
    flexDirection: 'row',
    marginTop: 4,
    alignItems: 'baseline'
},
textQty: {
    fontWeight: "bold",
    color: 'black'
},
textCurrency: {
    marginStart: 8,
    fontWeight: "bold",
    color: 'black'
},
textBigQty: {
    fontWeight: "bold",
    width: "100%",
    textAlign: "center",
    fontSize: 32,
    color: 'black'
},
btnContainer: {
    flexDirection: "row",
    justifyContent: 'center',
    marginTop: 6
},
buttonDecrement: {
    backgroundColor: Global.color.btn_yellow,
    paddingHorizontal: 18,
    borderBottomStartRadius: 8,
    borderTopStartRadius: 8
},
buttonIncrement: {
    backgroundColor: Global.color.btn_yellow,
    paddingHorizontal: 18,
    borderBottomEndRadius: 8,
    borderTopEndRadius: 8
},
buttonMinusGap: {
    marginEnd: 1
},
buttonPlusGap: {
    marginStart: 1
},
heading: {
    fontWeight: 'bold',
    fontSize: 32,
    color: Global.color.dark_gray,
    textAlign: 'center',
    marginTop: 30
},
subheading: {
    fontWeight: 'normal',
    fontSize: 20,
    color: Global.color.light_gray,
    textAlign: 'center',
    marginTop: 8
},
textSubamount: {
    fontWeight: 'bold',
    fontSize: 16,
    color: Global.color.light_gray,
    flex: 0.3,
    textAlign: 'right',
    paddingEnd: 18
},
textTotalAmount: {
    fontWeight: 'bold',
    fontSize: 22,
    color: 'black',
    flex: 0.3,
    textAlign: 'right',
    paddingEnd: 18
},
textSmall: {
    fontWeight: 'normal',
    fontSize: 12
},
line: {
    backgroundColor: 'lightgrey',
    height: 1,
    marginTop: 12
},
rowContainer: {
    flexDirection: 'row',
    marginTop: 16
},
btnCheckout: {
    alignItems: 'center',
    width: '100%',
    marginTop: 30
},
hide: {
    display: 'none'
},
iconEmptyCart: {
    width: 200,
    height: 200
},
emptyCartContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute'
},
  modalBG: {
    backgroundColor: Global.color.app_bg,
    padding: 24,
    alignItems: 'center',
    width: "80%",
    borderRadius: 10
  },
  modalTitle: {
    fontWeight: 'bold',
    fontSize: 24,
    color: Global.color.btn_yellow
  },
  modalBody: {
    fontSize: 16,
    color: Global.color.text_white,
    marginTop: 12,
    textAlign: 'center'
  },
  modalHeading: {
    fontSize: 28,
    color: Global.color.text_white,
    marginTop: 6,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  modalFooter: {
    flexDirection: "row",
    marginTop: 12
  },
  modalTransBG: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  image_size: {
    width: 200,
    height: 200
  },
  bg_popup_size: {
    width: '90%',
    height: 380,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10
  }
})