export default class Request {

  async post(url, params) {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        });
        return response.json();
      }
      catch (err) {
        return err;
      }
    }

    async get(url) {
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        });
        return response.json();
      }
      catch (err) {
        return err;
      }
    }

}