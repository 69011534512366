import CartData from "../model/util/CartData";

export default class ItemModifiersController {

  constructor(view) {
    this.view = view;

    const item = this.view.props.navigation.getParam('item');
    
    this.items = [];

    for (let i=0; i<item.quantity; i++) {
      this.items.push( JSON.parse(JSON.stringify(item)) );
    }

    this.view.state = {
      selectedItem: item,
      items: this.items,
      alertMsg: '',
      showAlert: 'none'
    };
  }

  resetItemsQuantity = () => {
    for (let x=0; x<this.items.length; x++) {
      this.items[x].quantity = 1;
    }
  }

  modIsPressed = (mod, cat) => {
    if ( cat.required == 1 ) {
      cat.items.forEach(item => {
        item.isSelected = false
      });
      mod.isSelected = true
    } else {
      if ( mod.isSelected ) {
        mod.isSelected = false
      } else {
        let totalSelectedItems = 0
        cat.items.forEach(item => {
          if ( item.isSelected ) {
            totalSelectedItems++
          }
        });
        if ( totalSelectedItems == cat.max_count ) {
          return
        }
        mod.isSelected = true
      }
    }

    let totalRequiredItems = 0
    let totalSelectedRequiredItems = 0

    this.view.state.selectedItem.modifiers.forEach(modifier => {
      if ( modifier.required === 1 ) {
        totalRequiredItems++
      }
    })

    this.view.state.selectedItem.modifiers.forEach(modifier => {
      if ( modifier.required === 1 ) {
        modifier.items.forEach(item => {
          if ( item.isSelected ) {
            totalSelectedRequiredItems++
          }
        })
      }
    })

    if ( totalRequiredItems === totalSelectedRequiredItems ) {
      this.view.setState({
        addToCartEnabled: true
      })
    }

    this.view.forceUpdate()
  }

  hideMixers = (selected_item_index, selected_modifier_index) => {
    for (let x=0; x<this.items.length; x++) {
      if ( x == selected_item_index ) {
        for (let i=0; i<this.items[x].modifiers.length; i++) {
          if ( i != selected_modifier_index ) {
            for (let j=0; j<this.items[x].modifiers[i].items.length; j++) {
              this.items[x].modifiers[i].items[j].opacity = 0.3;
              this.items[x].modifiers[i].items[j].disabled = true;
            }
          }
        }
      }
    }
  }

  unhideMixers = (selected_item_index, selected_modifier_index) => {
    for (let x=0; x<this.items.length; x++) {
      if ( x == selected_item_index ) {
        for (let i=0; i<this.items[x].modifiers.length; i++) {
          if ( i != selected_modifier_index ) {
            for (let j=0; j<this.items[x].modifiers[i].items.length; j++) {
              this.items[x].modifiers[i].items[j].opacity = 1.0;
              this.items[x].modifiers[i].items[j].disabled = false;
            }
          }
        }
      }
    }
  }

  setMustSelectCount = () => {
    this.modifiersMustSelectCount = 0;

    for (let x=0; x<this.items.length; x++) {
      for (let i=0; i<this.items[x].modifiers.length; i++) {
        if (this.items[x].modifiers[i].required == 1) {
          this.modifiersMustSelectCount++;
        }
      }
    }

  }

  deselectAllStates = (selected_modifier_id, selected_modifier_index) => {
    this.modifiersMustSelectCount = 0;

    for (let x=0; x<this.items.length; x++) {
      for (let i=0; i<this.items[x].modifiers.length; i++) {
        this.modifiersMustSelectCount++;
        for (let j=0; j<this.items[x].modifiers[i].items.length; j++) {
          if ( selected_modifier_id == null ) {
            this.items[x].modifiers[i].items[j].selected = false;
          } else if ( selected_modifier_id == this.items[x].modifiers[i].items[j].mod_cat_id ) {
            if ( x == selected_modifier_index ) {
              this.items[x].modifiers[i].items[j].selected = false;
            }
          }
        }
      }
    }

  }

  deselectSelectedItemStates = (selected_modifier_index) => {
    this.modifiersMustSelectCount = 0;

    for (let x=0; x<this.items.length; x++) {
      for (let i=0; i<this.items[x].modifiers.length; i++) {
        this.modifiersMustSelectCount++;
        for (let j=0; j<this.items[x].modifiers[i].items.length; j++) {
          if ( x == selected_modifier_index ) {
            this.items[x].modifiers[i].items[j].selected = false;
          }
        }
      }
    }

  }

  update = () => {
    this.view.setState({
      items: this.items
    });
  }

  updateCartItem = () => {
    const itemsReadyForCart = this.collapseSimilarSelectedItems();

    itemsReadyForCart.forEach((item, index) => {
      itemsReadyForCart[index].selected_mod_items = '';
      item.price = item.base_price;

      item.modifiers.forEach(modifier => {
        modifier.items.forEach(mod_item => {
          if ( mod_item.isSelected ) {
            itemsReadyForCart[index].selected_mod_items += '- ' + mod_item.name + '\n';
          }
        });
      });
    });

    CartData.deleteFromCart(this.view.state.selectedItem);

    CartData.addToCart(itemsReadyForCart);

    this.backIsPressed();
  }

  collapseSimilarSelectedItems = () => {
    let finalItems = [];
    let finalItemsCount = [];

    for (let x=0; x<this.items.length; x++) {
      for (let i=0; i<finalItems.length; i++) {
        if ( JSON.stringify(this.items[x]) == JSON.stringify(finalItems[i]) ) {
          // Old item found
          finalItemsCount[i] += 1;
          break;
        }
        if ( i == finalItems.length-1 ) {
          // New item found
          finalItemsCount.push(1);
          finalItems.push( this.items[x] );
          break;
        }
      }
      // Add initial item to run loop
      if (finalItems.length == 0) {
        finalItems.push( this.items[x] );
        finalItemsCount.push( 1 );
      }
    }

    for (let i=0; i<finalItems.length; i++) {
      finalItems[i].quantity = finalItemsCount[i];
    }

    return finalItems;
  }

  checkIfUserSelectedAllRequiredModifiers = () => {
    let selectedModifiersCount = 0;

    for (let x=0; x<this.items.length; x++) {
      for (let i=0; i<this.items[x].modifiers.length; i++) {
        for (let j=0; j<this.items[x].modifiers[i].items.length; j++) {
          if ( this.items[x].modifiers[i].required == 1 && this.items[x].modifiers[i].items[j].isSelected ) {
            selectedModifiersCount++;
          }
        }
      }
    }

    if ( this.modifiersMustSelectCount != selectedModifiersCount ) {
      return false;
    }

    return true;
  }

  backIsPressed = () => {
    this.view.props.navigation.goBack();
  }

}