export default class Global {

    // static baseUrl = 'http://127.0.0.1:8000/';
    static baseUrl = 'https://admin.oderupp.co/public/';


    static apiVersion = 'api/v1/';
    static storageDir = 'storage/';

    static apiUrl = Global.baseUrl + Global.apiVersion;
    static mediaUrl = Global.baseUrl + Global.storageDir;

    static maxItems = 10;
    static brandId = 1;
    static branchId = 1;
    static tableNo = 1;
    static popupOrderPlaced = 'none';
    static dataBackup = {}

    static color = {
        app_bg: '#0fdaef',
        alert: 'red',
        dark_gray: '#3f3f3f',
        gray: '#5b5b5b',
        light_gray: '#a8a8a8',
        disabled_gray: '#eeeeee'
    };

    static monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

}