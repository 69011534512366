import React, { Component } from 'react';
import { View, Image, StatusBar, StyleSheet } from 'react-native';
import SplashController from '../controller/SplashController';
import Global from '../model/util/Global';

class Splash extends Component {

  constructor(props) {
    super(props);
    
    this.controller = new SplashController(this);
  }

  componentDidMount() {
    this.controller.loadFonts();
  }

  render() {

    return (
      <View style = { Styles.container }>

        <StatusBar barStyle="light-content"/>

        <Image
          source = { require('../assets/logo.png') }
          resizeMode = { 'contain' }
          style = { Styles.logo_brand }
        />
        
      </View>
    );
  }

}

export default Splash;

const Styles = StyleSheet.create ({
  container: {
    width: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white'
  },
  logo: {
    height: 40,
    width: 110
  },
  logo_brand: {
    height: 200,
    width: 200
  },
  logo_container: {
    position: 'absolute',
    bottom: 6,
    right: 10
  }
})