import CartData from "../model/util/CartData";
import Global from "../model/util/Global";
import Api from "../model/Api";

export default class CartController {

  constructor(view) {
    this.view = view;

    this.bill = {
      total_items: 0,
      sub_total: 0,
      tax: 13,
      total: 0
    };

    this.defaultCurrency = 'Rs';

    this.view.state = {
      items: [],
      showModal: false,
      itemToDelete: {},
      selectedPayCash: true,
      selectedPayCard: false,
      orderEnabled: true
    };
  }

  showCheckout = () => {
    this.view.props.navigation.push('Checkout');
  }

  payOptionIsPressed = (selectedValue) => {
    if ( selectedValue === 'cash' ) {
      this.view.setState({
        selectedPayCash: true,
        selectedPayCard: false
      });
    } else {
      this.view.setState({
        selectedPayCash: false,
        selectedPayCard: true
      });
    }
  }

  update = () => {
    if ( CartData.items ) {
      this.view.setState({
        items: CartData.items
      });

      if ( CartData.items.length === 0 ) {
        // Force update bot nav
        this.view.props.navigation.setParams({});
      }
    }
  }

  increaseItemQuantity = (index) => {
    if ( this.view.state.items[index].quantity < Global.maxItems ) {
      this.view.setState(prevState => ({
        items: prevState.items.map((item, ind) => {
           return ind == index ? Object.assign(item, { quantity: item.quantity + 1 }) : item
        })
      }));
    }
  }

  decreaseItemQuantity = (index) => {
    if ( this.view.state.items[index].quantity > 1 ) {
      this.view.setState(prevState => ({
        items: prevState.items.map((item, ind) => {
           return ind == index ? Object.assign(item, { quantity: item.quantity - 1 }) : item
        })
      }));
    } else {
      this.view.setState({
        itemToDelete: this.view.state.items[index],
        showModal: true
      })
    }
  }

  openItemModifiers = (selectedItem) => {
    if ( selectedItem.modifiers.length > 0 ) {
      this.view.props.navigation.push('ItemModifiers', {
        item: selectedItem
      });
    }

    // if ( selectedItem.selected_type.modifiers != 'No' ) {
    //   this.view.props.navigation.push('ItemModifiers', {
    //     item: selectedItem
    //   });
    // }
  }

  calculateBill = () => {
    this.bill.sub_total = 0;
    this.bill.total_items = 0;

    this.view.state.items.forEach(item => {
      this.bill.sub_total += item.price * item.quantity;
      this.bill.total_items += item.quantity;
    });

    this.bill.total = this.bill.sub_total + (this.bill.sub_total * this.bill.tax / 100);

    this.bill.sub_total = this.bill.sub_total.toFixed(2);
    this.bill.total = this.bill.total.toFixed(2);
  }

  createOrder = () => {
    this.view.setState({
      orderEnabled: false
    })

    let orderedItems = [];

    this.view.state.items.forEach(item => {
      let modIds = [];

      item.modifiers.forEach(mod => {
        mod.items.forEach(modItem => {
          if ( modItem.isSelected ) {
            modIds.push(modItem.id);
          }
        });
      });

      const order = {
        id: item.id,
        quantity: item.quantity,
        modifier_ids: modIds
      }

      orderedItems.push(order);
    });

    const api = new Api();
    const params = {
      brand_id: Global.brandId,
      branch_id: Global.branchId,
      table_no: Global.tableNo,
      payment_mode: this.view.state.selectedPayCard ? 'card' : 'cash',
      comments: 'User comments',
      order_items: orderedItems
    };
  
    api.post(Global.apiUrl+'order/create', params)
      .then(result => {
          let orderTotal = 0;
          let orderTypes = {};

          result.order.status = result.order.status.name;

          result.order.items.forEach(item => {
            orderTotal += item.price;
            if (!(item.type in orderTypes)) {
              orderTypes[item.type] = item.quantity;
            } else {
              orderTypes[item.type] += item.quantity;
            }
          });
          
          result.order.total_price = orderTotal.toFixed(2);
          result.order.selected_types = '';

          for (let key in orderTypes) {
            if (orderTypes.hasOwnProperty(key)) {
              result.order.selected_types += orderTypes[key] + 'x ' + key + '  ';
            }
          }

          let date = new Date( result.order.created_at );
          let date2 = new Date( result.order.updated_at );
          
          result.order.created_at = Global.monthNames[date.getMonth()] + ' ' + (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ', ' + (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':' + (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ' hrs';
          result.order.updated_at = Global.monthNames[date2.getMonth()] + ' ' + (date2.getDate() < 10 ? '0'+date2.getDate() : date2.getDate()) + ', ' + (date2.getHours() < 10 ? '0'+date2.getHours() : date.getHours()) + ':' + (date2.getMinutes() < 10 ? '0'+date2.getMinutes() : date2.getMinutes()) + ' hrs';

          if ( result.order.status == 'Pending' ) {
            result.order.status_color = 'yellow';
          } else if ( result.order.status == 'Cooking' ) {
            result.order.status_color = 'darkorange';
          } else if ( result.order.status == 'Served' ) {
            result.order.status_color = 'green';
          } else if ( result.order.status == 'Cancelled' ) {
            result.order.status_color = 'red';
          }

          CartData.items = [];
          Global.popupOrderPlaced = 'flex';

          this.view.setState({
            orderEnabled: true
          })

          this.goToOrder();
        }
      )
      .catch(err => {
        console.log( err );
        this.view.setState({
          orderEnabled: true
        })
      });
  }

  backIsPressed = () => {
    this.view.props.navigation.goBack();
  }

  goToOrder = () => {
    this.view.props.navigation.navigate('Order');
  }

}