import React, { Component } from 'react';
import { View, StatusBar, Text, ScrollView, TouchableOpacity, Image, SafeAreaView, StyleSheet } from 'react-native';
import RadioForm, { RadioButton, RadioButtonInput, RadioButtonLabel } from 'react-native-simple-radio-button';
import CartController from '../controller/CartController';
import Global from '../model/util/Global';
import CartData from '../model/util/CartData';

class Cart extends Component {

  constructor(props) {
    super(props);
    
    this.controller = new CartController(this);
  }

  componentDidMount() {
    this.focusListener = this.props.navigation.addListener("didFocus", () => {
      this.controller.update();
    });
  }

  componentWillUnmount() {
    this.focusListener.remove();
  }

  render() {
    return (
      <SafeAreaView style = { Styles.container }>

        <StatusBar barStyle="light-content"/>

        <ScrollView style = { Styles.scrollContainer }>
          <Text style = { this.state.items == 0 ? Styles.hide : Styles.heading }>Place Your Order</Text>

          <Text style = { this.state.items == 0 ? Styles.hide : Styles.subheading }>Let our chefs do the magic!</Text>
          {
            this.state.items.map((item, index) => {
              
              if ( this.state.items.length-1 == index ) {
                this.controller.calculateBill();
              }

              return <TouchableOpacity key = { index } style = { Styles.itemContainer } onPress = {() => { this.controller.openItemModifiers(item); }}>
                <View style = {{ flex: 0.7 }}>
                  <View style = {{ flexDirection: 'row' }}>
                    <Image
                      source = {{ uri: Global.mediaUrl + item.image }}
                      style = { Styles.fitIcon }
                      resizeMode = { 'cover' }
                      />
                    <View>
                      <Text style = { Styles.textName }>{item.name}</Text>
                      <Text style = { Styles.textModifiers }>{item.selected_mod_items}</Text>
                    </View>
                  </View>
                </View>
                <View style = {{ flex: 0.3, alignItems: 'flex-end' }}>
                  <View style = { Styles.itemDetailContainer }>
                    <Text style = { Styles.textCurrency }>{item.currency}. {item.price}</Text>
                  </View>
                  <View style = { Styles.btnContainer }>
                    <TouchableOpacity onPress = {() => { this.controller.decreaseItemQuantity(index); }}>
                      <View style = { [Styles.buttonDecrement, Styles.buttonMinusGap, (item.quantity == 1 ? {borderColor: Global.color.alert} : {borderColor: Global.color.app_bg})] }>
                        <Text style = {{ fontSize: 24, color: 'black', textAlign: 'center' }}>-</Text>
                      </View>
                    </TouchableOpacity>
                    <Text style = { Styles.textBigQty }>{item.quantity}</Text>
                    <TouchableOpacity onPress = {() => { this.controller.increaseItemQuantity(index); }}>
                      <View style = { [Styles.buttonIncrement, Styles.buttonPlusGap] }>
                        <Text style = {{ fontSize: 24, color: 'black', textAlign: 'center' }}>+</Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                </View>
              </TouchableOpacity>
            })
          }

          <View style = { this.state.items == 0 ? Styles.hide : null }>
            <View style = {{ flexDirection: 'row', justifyContent: 'space-between', margin: 14 }}>
              <Text style = {{ color: Global.color.dark_gray, fontSize: 20, fontWeight: 'bold' }}>Payment Method</Text>
              <Text style = {{ color: Global.color.gray }}>Required</Text>
            </View>
            <RadioForm
                  formHorizontal={false}
                  animation={true}
                  style = {{ marginHorizontal: 12 }}
                >
              <RadioButton labelHorizontal={true} key={0} style={{paddingVertical: 12, borderRadius: 8, borderColor: Global.color.dark_gray, borderWidth: 1, marginVertical: 6}}>
                <RadioButtonInput
                  obj={{label: 'Pay by Cash', value: 0 }}
                  index={0}
                  isSelected={this.state.selectedPayCash}
                  onPress={(value) => {this.controller.payOptionIsPressed('cash')}}
                  borderWidth={2}
                  buttonInnerColor={Global.color.gray}
                  buttonOuterColor={Global.color.app_bg}
                  buttonSize={16}
                  buttonOuterSize={28}
                  buttonStyle={{}}
                  buttonWrapStyle={{marginLeft: 10}}
                />
                <RadioButtonLabel
                  obj={{label: 'Pay by Cash', value: 0 }}
                  index={0}
                  labelHorizontal={true}
                  onPress={(value) => {this.controller.payOptionIsPressed('cash')}}
                  labelStyle={{fontSize: 20, color: Global.color.dark_gray}}
                  labelWrapStyle={{flex: 1}}
                />
              </RadioButton>
              <RadioButton labelHorizontal={true} key={1} style={{paddingVertical: 12, borderRadius: 8, borderColor: Global.color.dark_gray, borderWidth: 1, marginVertical: 6}}>
                <RadioButtonInput
                  obj={{label: 'Pay by Card', value: 0 }}
                  index={1}
                  isSelected={this.state.selectedPayCard}
                  onPress={(value) => {this.controller.payOptionIsPressed('card')}}
                  borderWidth={2}
                  buttonInnerColor={Global.color.gray}
                  buttonOuterColor={Global.color.app_bg}
                  buttonSize={16}
                  buttonOuterSize={28}
                  buttonStyle={{}}
                  buttonWrapStyle={{marginLeft: 10}}
                />
                <RadioButtonLabel
                  obj={{label: 'Pay by Card', value: 0 }}
                  index={1}
                  labelHorizontal={true}
                  onPress={(value) => {this.controller.payOptionIsPressed('card')}}
                  labelStyle={{fontSize: 20, color: Global.color.dark_gray}}
                  labelWrapStyle={{flex: 1}}
                />
              </RadioButton>
            </RadioForm>
          </View>

          <View style = { this.state.items == 0 ? Styles.hide : null }>
            <Text style = {{ color: Global.color.dark_gray, fontSize: 20, fontWeight: 'bold', margin: 14 }}>Your Bill</Text>
            <View style = { Styles.rowContainer }>
              <Text style = { Styles.textSubamount }>Subtotal
                <Text style = { Styles.textSmall }>   ({this.controller.bill.total_items} items)</Text>
              </Text>
              <Text style = { Styles.textSubamount }>{this.state.items.length > 0 ? this.state.items[0].currency : this.controller.defaultCurrency}{this.controller.bill.sub_total}</Text>
            </View>
            <View style = { Styles.rowContainer }>
              <Text style = { Styles.textSubamount }>Service Charges
              </Text>
              <Text style = { Styles.textSubamount }>{this.state.items.length > 0 ? this.state.items[0].currency : this.controller.defaultCurrency}0</Text>
            </View>
            <View style = { Styles.rowContainer }>
              <Text style = { Styles.textSubamount }>Tax
                <Text style = { Styles.textSmall }> ({this.controller.bill.tax}%)</Text>
              </Text>
              <Text style = { Styles.textSubamount }>{this.state.items.length > 0 ? this.state.items[0].currency : this.controller.defaultCurrency}{this.controller.bill.sub_total * this.controller.bill.tax / 100}</Text>
            </View>
            <View style = { Styles.line } />
            <View style = { Styles.rowContainer }>
              <Text style = { Styles.textSubamount }>Order Total</Text>
              <Text style = { Styles.textTotalAmount }>{this.state.items.length > 0 ? this.state.items[0].currency : this.controller.defaultCurrency}{this.controller.bill.total}</Text>
            </View>
            <TouchableOpacity style = {[ { backgroundColor: Global.color.app_bg, paddingVertical: 14, borderRadius: 6, paddingHorizontal: 60, alignItems: 'center', justifyContent: 'center', marginHorizontal: 14, marginBottom: 8 }, this.state.orderEnabled ? {backgroundColor: Global.color.app_bg} : {backgroundColor: Global.color.disabled_gray} ]} disabled = { this.state.orderEnabled ? false : true } onPress = {() => { this.controller.createOrder() }}>
              <Text style = {{ color: 'white', marginStart: 6, fontWeight: 'bold', fontSize: 18 }}>Confirm Order</Text>
            </TouchableOpacity>
          </View>

          <View style = {{ height: 100 }} />
        </ScrollView>

        <View style = { this.state.items == 0 ? Styles.emptyCartContainer : Styles.hide }>
          <Image
            source = { require('../assets/empty_cart.png') }
            resizeMode = { 'contain' }
            style = { Styles.iconEmptyCart }
          />
          <Text style = {{ color: "black", fontSize: 34, fontWeight: 'bold' }}>Let's Add Food</Text>
          <Text style = {{ color: "black", fontSize: 20, fontWeight: 'bold' }}>Your cart is empty</Text>
        </View>

        <View style = {[{ position: 'absolute', height: '100%', width: '100%' }, (this.state.showModal) ? {display: 'flex'} : {display: 'none'}]}>
          <View style = { Styles.modalTransBG }>

            <View style = { Styles.modalBG }>

              <Text style = { Styles.modalTitle }>Remove item from cart!</Text>

              <Text style = { Styles.modalBody }>Are you sure you want to remove</Text>

              <Text style = { Styles.modalHeading }>{this.state.itemToDelete.name}?</Text>

              <View style = { Styles.modalFooter }>
                <TouchableOpacity style = { { backgroundColor: Global.color.alert, paddingVertical: 14, borderRadius: 6, paddingHorizontal: 30, alignItems: 'center', justifyContent: 'center', marginHorizontal: 14, marginBottom: 8 } } onPress = {() => { this.setState({ showModal: false }) }}>
                  <Text style = {{ color: 'white', marginStart: 6, fontWeight: 'bold', fontSize: 18 }}>Cancel</Text>
                </TouchableOpacity>

                <TouchableOpacity style = { { backgroundColor: Global.color.app_bg, paddingVertical: 14, borderRadius: 6, paddingHorizontal: 50, alignItems: 'center', justifyContent: 'center', marginHorizontal: 14, marginBottom: 8 } } onPress = { () => {
                      CartData.deleteFromCart(this.state.itemToDelete);
                      this.setState({ showModal: false });
                      this.controller.update();
                    } }>
                  <Text style = {{ color: 'white', marginStart: 6, fontWeight: 'bold', fontSize: 18 }}>OK</Text>
                </TouchableOpacity>
              </View>

            </View>
          </View>
        </View>
        
      </SafeAreaView>
    );
  }

}

export default Cart;

const Styles = StyleSheet.create ({
  container: {
    flex: 1,
    padding: 0,
    width: '100%',
    backgroundColor: Global.color.app_bg
},
textTitle: {
    fontWeight: "bold",
    width: "100%",
    fontSize: 26,
    marginTop: 16,
    marginStart: 20,
    color: 'white'
},
scrollContainer: {
    width: '100%',
    flex: 1,
    backgroundColor: 'white'
},
fitIcon: {
  margin: 0,
  width: 90,
  height: 90,
  borderRadius: 8,
  marginEnd: 8
},
itemContainer: {
    flexDirection: "row",
    backgroundColor: 'white',
    margin: 14,
    borderRadius: 6
},
textName: {
    fontWeight: 'bold',
    fontSize: 16,
    color: 'black'
},
textModifiers: {
    fontSize: 12,
    marginTop: 4,
    color: 'black'
},
itemDetailContainer: {
    flexDirection: 'row',
    marginTop: 4,
    alignItems: 'baseline'
},
textQty: {
    fontWeight: "bold",
    color: 'black'
},
textCurrency: {
    marginStart: 8,
    fontWeight: "bold",
    color: 'black'
},
textBigQty: {
    fontWeight: "bold",
    width: "100%",
    textAlign: "center",
    fontSize: 28,
    color: 'black'
},
btnContainer: {
    flexDirection: "row",
    justifyContent: 'center',
    marginTop: 8,
    gap: 6
},
buttonDecrement: {
    backgroundColor: 'white',
    height: 34,
    width: 34,
    borderWidth: 1,
    borderRadius: 8
},
buttonIncrement: {
    backgroundColor: 'white',
    height: 34,
    width: 34,
    borderColor: Global.color.app_bg,
    borderWidth: 1,
    borderRadius: 8
},
buttonMinusGap: {
    marginEnd: 1
},
buttonPlusGap: {
    marginStart: 1
},
heading: {
  fontWeight: 'bold',
  fontSize: 32,
  color: Global.color.dark_gray,
  textAlign: 'center',
  marginTop: 30
},
subheading: {
  fontWeight: 'normal',
  fontSize: 20,
  color: Global.color.light_gray,
  textAlign: 'center',
  marginTop: 8
},
textSubamount: {
    fontWeight: 'bold',
    fontSize: 16,
    color: Global.color.dark_gray,
    textAlign: 'right'
},
textTotalAmount: {
    fontWeight: 'bold',
    fontSize: 22,
    color: Global.color.dark_gray,
    textAlign: 'right'
},
textSmall: {
    fontWeight: 'normal',
    fontSize: 12
},
line: {
    backgroundColor: 'lightgrey',
    height: 1,
    margin: 14
},
rowContainer: {
    flexDirection: 'row',
    margin: 14,
    justifyContent: 'space-between'
},
btnCheckout: {
    alignItems: 'center',
    width: '100%',
    marginTop: 30
},
hide: {
    display: 'none'
},
iconEmptyCart: {
    width: 200,
    height: 200,
    marginBottom: 8
},
emptyCartContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute'
},
modalTransBG: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.8)',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalBG: {
    backgroundColor: 'white',
    padding: 24,
    alignItems: 'center',
    width: "80%",
    borderRadius: 10
  },
  modalTitle: {
    fontWeight: 'bold',
    fontSize: 24,
    color: Global.color.btn_yellow
  },
  modalBody: {
    fontSize: 16,
    color: Global.color.text_white,
    marginTop: 12,
    textAlign: 'center'
  },
  modalHeading: {
    fontSize: 28,
    color: Global.color.text_white,
    marginTop: 6,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  modalFooter: {
    flexDirection: "row",
    marginTop: 30
  }
})