import Global from "../model/util/Global";
import Api from "../model/Api";
import * as Font from 'expo-font';

export default class SplashController {

  constructor(view) {
    this.view = view;

    Global.brandId = new URLSearchParams(window.location.search).get("res")
    Global.branchId = new URLSearchParams(window.location.search).get("bra")
    Global.tableNo = new URLSearchParams(window.location.search).get("tab")

    if ( Global.brandId && Global.branchId && Global.tableNo ) {
      this.getMenu();
    }

    // this.view.state = {
    //   logo: ''
    // };
  }

  async loadFonts() {
    await Font.loadAsync({
      'Outfit-Bold': require('../assets/fonts/Outfit-Bold.ttf'),
      'Outfit-Regular': require('../assets/fonts/Outfit-Regular.ttf')
    });
  }

  // getData = () => {
  //   const api = new Api();

  //   api.get(Global.apiUrl+'brand/1')
  //   .then(result => {
  //       this.view.setState({
  //         logo: Global.mediaUrl + result.image
  //       });

  //       this.getMenu();
  //     }
  //   )
  //   .catch(err => {
  //     console.log( err );
  //   });
  // }

  getMenu = () => {
    const api = new Api();

    api.get(Global.apiUrl+'brand/'+Global.brandId+'/branch/'+Global.branchId+'/menu')
    .then(result => {
        console.log(result)
        setTimeout(() => {
          this.view.props.navigation.replace('HomeStack', {
            menu: result
          });
        }, 1500);
      }
    )
    .catch(err => {
      console.log( err );
    });
  }

}