import Api from "../model/Api";
import Global from "../model/util/Global";

export default class CallWaiterController {

  constructor(view) {
    this.view = view;

    this.selectedWaiterOpts = '';

    this.view.state = {
      askWaiterOptions: [
        {
          label: 'Bring the Bill',
          isSelected: false
        },
        {
          label: 'Bring Tissues',
          isSelected: false
        },
        {
          label: 'Bring Ketchup',
          isSelected: false
        },
        {
          label: 'Just Come Here',
          isSelected: false
        }
      ],
      review: '',
      postEnabled: false,
      callEnabled: false,
      selectedFace: '',
      displayPopup: 'none',
      displayWaiterPopup: 'none'
    };
  }

  faceIsPressed = (value) => {
    this.view.setState({
      postEnabled: true,
      selectedFace: value
    })
  }

  optionIsPressed = (optionIndex) => {
    let waiterOpts = [];
    let isSelected = false;
    this.selectedWaiterOpts = '';

    this.view.state.askWaiterOptions.forEach((obj, ind) => {
      if ( optionIndex == ind ) {
        obj.isSelected = !obj.isSelected;
        waiterOpts.push(obj);
      } else {
        waiterOpts.push(obj);
      }
    })

    waiterOpts.forEach(item => {
      if (item.isSelected) {
        isSelected = true
        this.selectedWaiterOpts += item.label + ','
      }
    })

    this.selectedWaiterOpts = this.selectedWaiterOpts.slice(0, -1)

    this.view.setState({
      askWaiterOptions: waiterOpts,
      callEnabled: isSelected
    });
  }

  callWaiter = () => {
    this.view.state.askWaiterOptions.forEach(obj => {
      obj.isSelected = false
    })

    this.view.setState({
      askWaiterOptions: this.view.state.askWaiterOptions,
      callEnabled: false
    });

    const api = new Api();
    const params = {
      brand_id: Global.brandId,
      branch_id: Global.branchId,
      table_no: Global.tableNo,
      msg: this.selectedWaiterOpts
    };
  
    api.post(Global.apiUrl+'waiter/call', params)
      .then(result => {
          this.view.setState({
            displayWaiterPopup: 'flex'
          });
        }
      )
      .catch(err => {
        console.log( err );
      });
  }

  postReview = () => {
    const api = new Api();
    const params = {
      brand_id: 1,
      branch_id: 1,
      comment: this.view.state.review,
      rating: this.view.state.selectedFace
    };
  
    api.post(Global.apiUrl+'review/save', params)
      .then(result => {
          console.log(result);

          this.view.setState({
            postEnabled: false,
            selectedFace: '',
            review: '',
            displayPopup: 'flex'
          })
        }
      )
      .catch(err => {
        console.log( err );
      });
  }

  hidePopup = () => {
    this.view.setState({
      displayPopup: 'none'
    })
  }

  hideWaiterPopup = () => {
    this.view.setState({
      displayWaiterPopup: 'none'
    })
  }

}