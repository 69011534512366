import React, { Component } from 'react';
import { View, StatusBar, Text, ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import ItemModifiersController from '../controller/ItemModifiersController';
import { MaterialIcons } from '@expo/vector-icons';
import Global from '../model/util/Global';
import RadioForm, { RadioButton, RadioButtonInput, RadioButtonLabel } from 'react-native-simple-radio-button';

class ItemModifiers extends Component {

  constructor(props) {
    super(props);
    
    this.controller = new ItemModifiersController(this);
  }

  render() {

    return (
        <View style = { Styles.container }>
          
          <StatusBar barStyle="dark-content"/>

          <Text style = {{ fontWeight: "bold", width: "100%", fontSize: 20, textAlign: "center", marginTop: 26, color: 'black' }}>Edit your order</Text>

          <TouchableOpacity style={{ position: 'absolute', marginTop: 25, marginStart: 10 }} onPress = {() => { this.controller.backIsPressed(); }}>
              <MaterialIcons name="arrow-back" size={36} color="black" />
          </TouchableOpacity>

          <Text style = { Styles.heading }>{this.state.items[0].name}</Text>

          <View style = {{ flexDirection: 'row', alignItems: 'baseline' }}>
            <Text style = {{ fontWeight: "bold", fontSize: 16, color: 'black', marginStart: 16 }}>{this.state.items[0].quantity}x</Text>
          </View>

          <View style = { Styles.line } />
          
          <ScrollView showsVerticalScrollIndicator = { false } style = { Styles.scrollContainer }>
            <View>
              {
                this.state.items.map((element, ind) => {
                  return <View key = { ind } style = { Styles.gapTop }>
                      <View style = { Styles.text }>
                        <Text style = { Styles.textRegular }>Select choices for item {ind+1}</Text>
                      </View>
                      {
                        element.modifiers.map((item, index) => {
                          return <View key={index}>
                              <View style = {{ flexDirection: 'row', marginHorizontal: 0, alignItems: 'baseline', marginTop: 12, marginBottom: 6 }}>
                                <View style = {{ flexDirection: 'row', flex: 0.7, alignItems: 'baseline' }}>
                                  <Text style = {{ color: Global.color.dark_gray, fontSize: 24, fontWeight: '600' }}>{item.name}</Text>
                                  {
                                    item.required == 0 ? <Text style = {{ color: Global.color.dark_gray }}> (Select upto {item.max_count})</Text> : null
                                  }
                                </View>
                                <Text style = {{ color: Global.color.dark_gray, flex: 0.3, textAlign: 'right' }}>{item.required == 1 ? '1 Required' : 'Optional'}</Text>
                              </View>
                              <RadioForm
                                  formHorizontal={false}
                                  animation={true}
                                  key={index}
                                  style = {{ marginHorizontal: 0 }}
                                >
                              {
                                item.items.map((mod, ind) => {
                                    return <RadioButton labelHorizontal={true} key={ind} style={{paddingVertical: 12, borderRadius: 8, borderColor: Global.color.dark_gray, borderWidth: 1, marginVertical: 6}}>
                                                <RadioButtonInput
                                                  obj={{label: mod.name, value: mod.id }}
                                                  index={ind}
                                                  isSelected={mod.isSelected}
                                                  onPress={(value) => {this.controller.modIsPressed(mod, item)}}
                                                  borderWidth={2}
                                                  buttonInnerColor={Global.color.gray}
                                                  buttonOuterColor={Global.color.app_bg}
                                                  buttonSize={item.required == 1 ? 16 : null}
                                                  buttonOuterSize={item.required == 1 ? 28 : null}
                                                  buttonStyle={{}}
                                                  buttonWrapStyle={{marginLeft: 10}}
                                                />
                                                <RadioButtonLabel
                                                  obj={{label: mod.name, value: mod.id }}
                                                  index={ind}
                                                  labelHorizontal={true}
                                                  onPress={(value) => {this.controller.modIsPressed(mod, item)}}
                                                  labelStyle={{fontSize: 20, color: Global.color.dark_gray}}
                                                  labelWrapStyle={{flex: 1}}
                                                />
                                                {
                                                  item.required == 0 ? <Text style = {{ color: Global.color.dark_gray, fontSize: 18, fontWeight: 'normal', marginEnd: 10, textAlign: 'center' }}>Rs. {mod.price}</Text> : null
                                                }
                                            </RadioButton>
                                })
                              }
                            </RadioForm>
                          </View>
                        })
                      }
                  </View>
                })
              }
            </View>
          </ScrollView>

          <TouchableOpacity style = {{ paddingVertical: 10, borderRadius: 6, paddingHorizontal: 60, alignItems: 'center', justifyContent: 'center', backgroundColor: Global.color.app_bg, margin: 16 }} onPress = {() => { this.controller.updateCartItem(); }}>
            <Text style = {{ color: 'white', marginStart: 6, fontWeight: 'bold', fontSize: 18 }}>Update</Text>
          </TouchableOpacity>

          <View style = {[ Styles.modalTransBG, {display: this.state.showAlert} ]}>
            <View style = { Styles.modalBG }>

              <Text style = { Styles.modalTitle }>Alert</Text>

              <Text style = { Styles.modalBody }>{this.state.alertMsg}</Text>

              <View style = { Styles.modalFooter }>
                <TouchableOpacity style = {{ paddingVertical: 10, borderRadius: 6, paddingHorizontal: 60, alignItems: 'center', justifyContent: 'center', backgroundColor: Global.color.app_bg, marginTop: 15 }} onPress = {() => { this.setState({ showAlert: 'none' }); }}>
                  <Text style = {{ color: 'white', marginStart: 6, fontWeight: 'bold', fontSize: 18 }}>OK</Text>
                </TouchableOpacity>
              </View>

            </View>
          </View>
          
        </View>
    );
  }

}

export default ItemModifiers;

const Styles = StyleSheet.create ({
  container: {
    flex: 1,
    width: '100%'
  },
  option: {
    margin: 3,
    width: '50%',
    borderRadius: 6,
    paddingVertical: 5
  },
  optionActive: {
    backgroundColor: Global.color.btn_yellow
  },
  optionInactive: {
    backgroundColor: '#E8E8E8'
  },
  opacityInactive: {
    opacity: 0.3
  },
  opacityActive: {
    opacity: 1
  },
  heading: {
    fontWeight: "bold",
    fontSize: 22,
    color: 'black',
    padding: 16
  },
  line: {
    backgroundColor: 'lightgrey',
    height: 1,
    marginTop: 8
  },
  scrollContainer: {
    flex: 1,
    marginBottom: 16,
    padding: 16
  },
  gapTop: {
    marginTop: 18
  },
  text: {
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  textRegular: {
    fontSize: 12,
    color: 'black'
  },
  textBold: {
    fontWeight: "bold",
    fontSize: 14,
    marginStart: 4,
    color: 'black'
  },
  modifierContainer: {
    marginTop: 12,
    alignContent: 'center',
    justifyContent: 'center'
  },
  modifierTitle: {
    fontWeight: "bold",
    fontSize: 22,
    color: 'black'
  },
  optionsContainer: {
    flexDirection: "row",
    marginTop: 4
  },
  optionGapStart: {
    padding: 10,
    color: 'black'
  },
  requestContainer: {
    flexDirection: 'row',
    backgroundColor: '#E8E8E8',
    marginTop: 18,
    borderRadius: 8
  },
  btnContainer: {
    alignItems: 'center',
    width: '100%',
    bottom: 8
  },
  flatListContainer: {
    width: '100%'
  },
  show: {
    display: 'flex'
  },
  hide: {
    display: 'none'
  },
  modalTransBG: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  modalBG: {
      backgroundColor: Global.color.app_bg,
      padding: 24,
      alignItems: 'center',
      width: "80%",
      borderRadius: 10
  },
  modalTitle: {
      fontWeight: 'bold',
      fontSize: 24,
      color: Global.color.btn_yellow,
      marginTop: 12
  },
  modalBody: {
      fontSize: 16,
      color: Global.color.text_white,
      marginTop: 12,
      textAlign: 'center'
  },
  modalFooter: {
      flexDirection: "row",
      marginTop: 12
  },
  dotActive: {
    backgroundColor: '#333',
    width: 20,
    height: 20,
    borderRadius: 10,
    alignSelf: 'center',
    marginStart: 10
  },
  dotInactive: {
    backgroundColor: '#D3D3D3',
    width: 20,
    height: 20,
    borderRadius: 10,
    alignSelf: 'center',
    marginStart: 10
  }
})