import React, { Component } from 'react';
import { View, StatusBar, Text, ScrollView, TouchableOpacity, SafeAreaView, StyleSheet, Image } from 'react-native';
import OrderController from '../controller/OrderController';
import Global from '../model/util/Global';

class Order extends Component {

  constructor(props) {
    super(props);
    
    this.controller = new OrderController(this);
  }

  componentDidMount() {
    this.focusListener = this.props.navigation.addListener("didFocus", () => {
      this.controller.loadOrders()
    });
  }

  componentWillUnmount() {
    this.focusListener.remove();
  }

  render() {
    return (
      <SafeAreaView style = { Styles.container }>

        <StatusBar barStyle="light-content"/>

        <ScrollView style = { Styles.scrollContainer }>

          <Text style = { Styles.heading }>Your Orders</Text>

          <Text style = { Styles.subheading }>Your today's orders are below</Text>

          {
            this.state.orders.map((item, index) => {
              let bgColor = '#F7CB73' // Pending color

              if ( item.status.name === 'Cooking' ) {
                bgColor = 'orange'
              } else if ( item.status.name === 'Served' ) {
                bgColor = 'green'
              } else if ( item.status.name === 'Cancelled' ) {
                bgColor = Global.color.light_gray
              }

              let itemsDetails = ''
              let itemPrice = 0

              item.items.forEach(item => {
                itemsDetails += item.quantity + 'x ' + item.name + '\n'
                itemPrice += item.price
              })

              return <TouchableOpacity key = { index } style = { Styles.itemContainer } onPress = {() => { this.controller.openItemDetails('item') }}>
                  <View style = {{ flex: 0.7 }}>
                    <View style = {{ flexDirection: 'row' }}>
                      <View style = {{ width: 20, height: 20, borderRadius: 3, backgroundColor: bgColor }} />
                      <View style = {{ marginStart: 6 }}>
                        <Text style = { Styles.textName }>{item.status.name}</Text>
                        <Text style = { Styles.textModifiers }>{itemsDetails}</Text>
                      </View>
                    </View>
                  </View>
                  <View style = {{ flex: 0.3, alignItems: 'flex-end' }}>
                    <View style = { Styles.itemDetailContainer }>
                      <Text style = { Styles.textCurrency }>Rs. {itemPrice + (itemPrice * this.state.bill.tax / 100)}</Text>
                    </View>
                  </View>
                </TouchableOpacity>
            })
          }

          <View style = {{ height: 100 }} />
          
        </ScrollView>

        <View style = {[ Styles.modalTransBG, {display: Global.popupOrderPlaced} ]}>
          <View style = { Styles.bg_popup_size }>
            <Image
              source = { require( '../assets/icon_order_placed.png' ) }
              resizeMode = { 'contain' }
              style = { Styles.image_size }
            />
            <Text style = { Styles.heading }>Order Placed!</Text>
            <TouchableOpacity style = {{ paddingVertical: 10, borderRadius: 6, paddingHorizontal: 60, alignItems: 'center', justifyContent: 'center', backgroundColor: Global.color.app_bg, marginTop: 15 }} onPress = {() => { this.controller.hidePopup(); }}>
              <Text style = {{ color: 'white', marginStart: 6, fontWeight: 'bold', fontSize: 18 }}>Great</Text>
            </TouchableOpacity>
          </View>
        </View>

      </SafeAreaView>
    );
  }

}

export default Order;

const Styles = StyleSheet.create ({
  container: {
    flex: 1,
    padding: 0,
    width: '100%',
    backgroundColor: Global.color.app_bg
  },
  heading: {
    fontSize: 32,
    color: Global.color.dark_gray,
    textAlign: 'center',
    marginTop: 30,
    fontFamily: 'Outfit-Bold'
  },
  subheading: {
      fontSize: 20,
      color: Global.color.light_gray,
      textAlign: 'center',
      marginVertical: 8,
      fontFamily: 'Outfit-Regular'
  },
  scrollContainer: {
      width: '100%',
      flex: 1,
      backgroundColor: 'white'
  },
  textName: {
    fontWeight: 'bold',
    fontSize: 16,
    color: 'black'
  },
  textCurrency: {
    marginStart: 8,
    fontWeight: "bold",
    color: 'black'
  },
  itemDetailContainer: {
    flexDirection: 'row',
    marginTop: 4,
    alignItems: 'baseline'
  },
  textModifiers: {
    fontSize: 12,
    marginTop: 4,
    color: 'black'
  },
  itemContainer: {
    flexDirection: "row",
    backgroundColor: 'white',
    marginHorizontal: 14,
    marginVertical: 6,
    borderRadius: 12,
    borderColor: Global.color.light_gray,
    borderWidth: 1,
    padding: 12
  },
  modalTransBG: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  image_size: {
    width: 200,
    height: 200
  },
  bg_popup_size: {
    width: '90%',
    height: 380,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10
  }
})