import Request from "./network/Request";

export default class Api {

  // If Internet available
  async post(url, params) {
    try {
      const request = new Request();

      const response = await request.post(url, params);
      return response;
    }
    catch (err) {
      return err;
    }
  }

  async get(url) {
    try {
      const request = new Request();

      const response = await request.get(url);
      return response;
    }
    catch (err) {
      return err;
    }
  }

  // If Internet unavailable

}