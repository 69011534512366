import React, { Component } from 'react';
import Navigator from './controller/navigation/Navigator';

class App extends Component {

 render() {
    return (
      <Navigator/>
    )
  }
}

export default App;