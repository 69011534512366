import CartData from "../model/util/CartData";
import Global from "../model/util/Global";

export default class MenuController {

  constructor(view) {
    this.view = view;

    let menuData = this.view.props.navigation.getParam('menu');

    Global.dataBackup = JSON.parse(JSON.stringify(menuData));

    this.removableIndexes = [];

    this.view.state = {
      data: menuData,
      categoryIndex: 0,
      refresh: false,
      popupVisibility: false,
      addToCartEnabled: false,
      selectedItem: {
        modifiers: []
      }
    };
  }

  addToCart = () => {
    let readyForCart = true;

    this.view.state.selectedItem.modifiers.forEach(modifier => {
      if ( modifier.required == 1 ) {
        let allModsUnselected = true;
        
        modifier.items.forEach(item => {
          if ( item.isSelected ) {
            allModsUnselected = false;
          }
        });
        
        if ( allModsUnselected ) {
          readyForCart = false;
          alert( 'Please select ' + modifier.name );
        }
      }
    });

    if ( readyForCart ) {
      let tempSelItem = JSON.parse(JSON.stringify(this.view.state.selectedItem));
      tempSelItem.selected_mod_items = '';
      tempSelItem.currency = this.view.state.data.currency;
      tempSelItem.modifiers.forEach(modifier => {
        modifier.items.forEach(item => {
          if ( item.isSelected ) {
            tempSelItem.selected_mod_items += '- ' + item.name + '\n';
          }
        });
      });

      CartData.addToCart([tempSelItem]);
      // this.refreshMenu();
      this.view.setState({
        popupVisibility: false
      });

      // Force update bot nav
      this.view.props.navigation.setParams({});
    }
  }

  refreshMenu = () => {
    this.view.state.data.menu_categories.forEach(category => {
      category.items.forEach(item => {
        CartData.items.forEach(cartItem => {
          if ( item.id == cartItem.id ) {
            item.addedToCart = true;
          }
        });
      });
    });
  }

  increaseQty = () => {
    if ( this.view.state.selectedItem.quantity == 10 ) {
      return;
    }
    this.view.setState(prevState => ({
      selectedItem: {
        ...prevState.selectedItem,
        quantity: prevState.selectedItem.quantity + 1
      }
    }));
  }

  decreaseQty = () => {
    if ( this.view.state.selectedItem.quantity == 1 ) {
      return;
    }
    this.view.setState(prevState => ({
      selectedItem: {
        ...prevState.selectedItem,
        quantity: prevState.selectedItem.quantity - 1
      }
    }));
  }

  modIsPressed = (mod, cat) => {
    if ( cat.required == 1 ) {
      cat.items.forEach(item => {
        item.isSelected = false
      });
      mod.isSelected = true
    } else {
      if ( mod.isSelected ) {
        mod.isSelected = false
      } else {
        let totalSelectedItems = 0
        cat.items.forEach(item => {
          if ( item.isSelected ) {
            totalSelectedItems++
          }
        });
        if ( totalSelectedItems == cat.max_count ) {
          return
        }
        mod.isSelected = true
      }
    }

    let totalRequiredItems = 0
    let totalSelectedRequiredItems = 0

    this.view.state.selectedItem.modifiers.forEach(modifier => {
      if ( modifier.required === 1 ) {
        totalRequiredItems++
      }
    })

    this.view.state.selectedItem.modifiers.forEach(modifier => {
      if ( modifier.required === 1 ) {
        modifier.items.forEach(item => {
          if ( item.isSelected ) {
            totalSelectedRequiredItems++
          }
        })
      }
    })

    if ( totalRequiredItems === totalSelectedRequiredItems ) {
      this.view.setState({
        addToCartEnabled: true
      })
    }

    this.view.forceUpdate()
  }

  readText = (text) => {
    this.view.state.data = JSON.parse(JSON.stringify(Global.dataBackup));
    this.removableIndexes = [];

    Global.dataBackup.menu_categories.forEach((cat, index) => {
      if ( !cat.name.toLowerCase().includes(text.toLowerCase()) ) {
        //console.log(cat.name);
        //console.log(index);
        this.removableIndexes.push(index);
      }
    });

    for (let i = this.removableIndexes.length-1; i >= 0; i--) {
      this.view.state.data.menu_categories.splice(this.removableIndexes[i], 1);
    }

    //console.log('-----------');
    //console.log( this.view.state.data.menu_categories );

    this.view.setState({ 
      refresh: !this.view.state.refresh
    });
  }

  addIsPressed = (item) => {
    item.quantity = 1;

    let allOptionals = true;

    item.modifiers.forEach(modifier => {
      modifier.items.forEach(item => {
        item.isSelected = false;
      });

      if (modifier.required === 1) {
        allOptionals = false;
      }
    });

    this.view.setState({
      selectedItem: item,
      popupVisibility: true,
      addToCartEnabled: (item.modifiers.length == 0 || allOptionals) ? true : false
    });
  }

  collapseSimilarSelectedItems = (items) => {
    let finalItems = [];
    let finalItemsCount = [];

    for (let x=0; x<items.length; x++) {
      for (let i=0; i<finalItems.length; i++) {
        if ( JSON.stringify(items[x]) == JSON.stringify(finalItems[i]) ) {
          // Old item found
          finalItemsCount[i] += 1;
          break;
        }
        if ( i == finalItems.length-1 ) {
          // New item found
          finalItemsCount.push(1);
          finalItems.push( items[x] );
          break;
        }
      }
      // Add initial item to run loop
      if (finalItems.length == 0) {
        finalItems.push( items[x] );
        finalItemsCount.push( 1 );
      }
    }

    for (let i=0; i<finalItems.length; i++) {
      finalItems[i].quantity = finalItemsCount[i];
    }

    return finalItems;
  }

  closePopup = () => {
    this.view.setState({ 
      popupVisibility: false
    });
  }

  onCategoryPressed = (index) => {
    this.view.flatListRef.scrollToIndex({
      index: index,
      animated: true
    })

    this.view.setState({
      categoryIndex: index
    });
  }

  onViewableItemsChanged = ({ viewableItems, changed }) => {
    if (viewableItems.length > 0) {
      this.view.setState({
        categoryIndex: viewableItems[0].index,
        refresh: !this.view.state.refresh
      });
    }
  }

}