import React from 'react';
import { createAppContainer } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import Global from '../../model/util/Global';
import { Image, StyleSheet, View } from 'react-native';
import Splash from '../../view/Splash';
import ItemModifiers from '../../view/ItemModifiers';
import Menu from '../../view/Menu';
import Cart from '../../view/Cart';
import Order from '../../view/Order';
import CallWaiter from '../../view/CallWaiter';
import CartData from '../../model/util/CartData';

const HomeStack = createBottomTabNavigator({
    Menu: {
      screen: Menu,
      navigationOptions: {
        title: '',
        tabBarOptions: {
          activeTintColor: Global.color.app_bg,
          inactiveTintColor: Global.color.app_bg,
          style: {
            backgroundColor: Global.color.dark_gray,
            height: 60,
            position: 'absolute',
            borderRadius: 10,
            bottom: 20,
            marginStart: 10,
            marginEnd: 10
          }
        },
        tabBarIcon: ({ focused, tintColor }) => {
          return focused ? <Image
            source = { require( '../../assets/menu.png' ) }
            resizeMode = { 'contain' }
            style = { [Styles.icon_size, Styles.icon_tint] }
          /> : <Image
            source = { require( '../../assets/menu.png' ) }
            resizeMode = { 'contain' }
            style = { Styles.icon_size }
          />;
        }
      }
    },
    Cart: {
      screen: Cart,
      navigationOptions: {
        title: '',
        tabBarOptions: {
          activeTintColor: Global.color.app_bg,
          inactiveTintColor: Global.color.app_bg,
          style: {
            backgroundColor: Global.color.dark_gray,
            height: 60,
            position: 'absolute',
            borderRadius: 10,
            bottom: 20,
            marginStart: 10,
            marginEnd: 10
          }
        },
        tabBarIcon: ({ focused, tintColor }) => {
          return focused ? <View>
            {
              CartData.items ? (CartData.items.length > 0) ? <View style = {{ width: 10, height: 10, backgroundColor: Global.color.app_bg, borderRadius: 30, position: 'absolute', right: -5 }} /> : null : null
            }
            <Image
              source = { require( '../../assets/cart.png' ) }
              resizeMode = { 'contain' }
              style = { [Styles.icon_size, Styles.icon_tint] }
            />
          </View> : <View>
            {
              CartData.items ? (CartData.items.length > 0) ? <View style = {{ width: 10, height: 10, backgroundColor: Global.color.app_bg, borderRadius: 30, position: 'absolute', right: -5 }} /> : null : null
            }
            <Image
              source = { require( '../../assets/cart.png' ) }
              resizeMode = { 'contain' }
              style = { Styles.icon_size }
            />
          </View>;
        }
      }
    },
    Order: {
      screen: Order,
      navigationOptions: {
        title: '',
        tabBarOptions: {
          activeTintColor: Global.color.app_bg,
          inactiveTintColor: Global.color.app_bg,
          style: {
            backgroundColor: Global.color.dark_gray,
            height: 60,
            position: 'absolute',
            borderRadius: 10,
            bottom: 20,
            marginStart: 10,
            marginEnd: 10
          }
        },
        tabBarIcon: ({ focused, tintColor }) => {
          return focused ? <Image
            source = { require( '../../assets/order.png' ) }
            resizeMode = { 'contain' }
            style = { [Styles.icon_size, Styles.icon_tint] }
          /> : <Image
            source = { require( '../../assets/order.png' ) }
            resizeMode = { 'contain' }
            style = { Styles.icon_size }
          />;
        }
      }
    },
    CallWaiter: {
      screen: CallWaiter,
      navigationOptions: {
        title: '',
        tabBarOptions: {
          activeTintColor: Global.color.app_bg,
          inactiveTintColor: Global.color.app_bg,
          style: {
            backgroundColor: Global.color.dark_gray,
            height: 60,
            position: 'absolute',
            borderRadius: 10,
            bottom: 20,
            marginStart: 10,
            marginEnd: 10
          }
        },
        tabBarIcon: ({ focused, tintColor }) => {
          return focused ? <Image
            source = { require( '../../assets/callwaiter.png' ) }
            resizeMode = { 'contain' }
            style = { [Styles.icon_size, Styles.icon_tint] }
          /> : <Image
            source = { require( '../../assets/callwaiter.png' ) }
            resizeMode = { 'contain' }
            style = { Styles.icon_size }
          />;
        }
      }
    }
  });
  
const MainStack = createStackNavigator({
    Splash: {
        screen: Splash,
        navigationOptions: {
          headerShown: false
        }
    },
    ItemModifiers: {
      screen: ItemModifiers,
      navigationOptions: {
        headerShown: false
      }
    },
    HomeStack: {
      screen: HomeStack,
      navigationOptions: {
        headerShown: false
      }
    }
});

const Styles = StyleSheet.create({
    icon_size: {
        width: 28,
        height: 28
    },
    icon_tint: {
      tintColor: Global.color.app_bg
  }
});

export default createAppContainer(MainStack);